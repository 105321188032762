import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import { useState } from "react";

const DefaultRegionMargin = 18;

export default function MainContentComponent() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const directionButtons = (direction) => {
    return (
      <button
        is="button-gazable"
        dwell-animation="ltr"
        data-gaze-region-margin={DefaultRegionMargin}
        class={
          direction === "Next"
            ? "btn btn-secondary btn-lg button-next"
            : "btn btn-secondary btn-lg button-prev"
        }
      >
        {direction}
      </button>
    );
  };

  return (
    <>
      <Carousel
        interval={null}
        controls={true}
        variant="dark"
        activeIndex={index}
        onSelect={handleSelect}
        style={{ height: "75vh" }}
        nextIcon={directionButtons("Next")}
        prevIcon={directionButtons("Previous")}
      >
        <Carousel.Item className="td-carousel-item">
          <Row className="pb-4 justify-content-center">
            <Col xs={{ span: 4 }}>
              <div is="gazable-element" class="card">
                <div class="card-body">
                  <img alt="2048" src="2048.png" />
                  <div class="card-title h5 pt-4">2048</div>
                  <div class="card-text">
                    Use your arrow keys to move the tiles. When two tiles with
                    the same number touch, they merge into one!
                  </div>
                </div>
                <div class="card-footer">
                  <a
                    is="a-gazable"
                    href="https://2048.tdbrowse.net/"
                    dwell-animation="ltr"
                    data-gaze-region-margin={DefaultRegionMargin}
                    class="btn btn-primary btn-lg"
                  >
                    Open example
                  </a>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 4 }}>
              <div is="gazable-element" class="card">
                <div class="card-body">
                  <img alt="checkers" src="checkersgame.png" />
                  <div class="card-title h5 pt-4">Checkers</div>
                  <div class="card-text">
                    Checkers browser game player vs. AI. This project is perfect
                    to replicate if you want to better understand the basics of
                    JS and how it talks to HTML (the DOM).
                  </div>
                </div>
                <div class="card-footer">
                  <a
                    is="a-gazable"
                    href="https://checkers.tdbrowse.net/"
                    dwell-animation="ltr"
                    data-gaze-region-margin={DefaultRegionMargin}
                    class="btn btn-primary btn-lg"
                  >
                    Open example
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Carousel.Item>

        <Carousel.Item className="td-carousel-item">
          <Row className="pb-4 justify-content-center">
            <Col xs={{ span: 4 }}>
              <div is="gazable-element" class="card">
                <div class="card-body">
                  <img alt="tictactoe" src="tictactoe.png" />
                  <div class="card-title h5 pt-4">TicTacToe</div>
                  <div class="card-text text-center">
                    TicTacToe browser game.
                    <br />
                    Player vs Player or <br /> Player vs Computer (three
                    difficulties)
                  </div>
                </div>
                <div class="card-footer">
                  <a
                    is="a-gazable"
                    href="https://tictactoe.tdbrowse.net/"
                    dwell-animation="ltr"
                    data-gaze-region-margin={DefaultRegionMargin}
                    class="btn btn-primary btn-lg"
                  >
                    Open example
                  </a>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 4 }}>
              <div is="gazable-element" class="card">
                <div class="card-body">
                  <img alt="towerdefense" src="towerdefense.png" />
                  <div class="card-title h5 pt-4">Tower Defense</div>
                  <div class="card-text text-center">
                    Tower Defense browser game.
                    <br />
                    Defend your kingdom against Slimy Empire <br />
                    Endless gameplay
                  </div>
                </div>
                <div class="card-footer">
                  <a
                    is="a-gazable"
                    href="https://towerdefense.tdbrowse.net/"
                    dwell-animation="ltr"
                    data-gaze-region-margin={DefaultRegionMargin}
                    class="btn btn-primary btn-lg"
                  >
                    Open example
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Carousel.Item>

        <Carousel.Item className="td-carousel-item">
          <Row className="pb-4 justify-content-center">
            <Col xs={{ span: 4 }}>
              <div is="gazable-element" class="card">
                <div class="card-body">
                  <img alt="cattube" src="cattube.png" />
                  <div class="card-title h5 pt-4">CatTube</div>
                  <div class="card-text text-center">
                    Web application that provides quick and easy access to your
                    favorite YouTube videos.
                  </div>
                </div>
                <div class="card-footer">
                  <a
                    is="a-gazable"
                    href="https://cattube.tdbrowse.net/"
                    dwell-animation="ltr"
                    data-gaze-region-margin={DefaultRegionMargin}
                    class="btn btn-primary btn-lg"
                  >
                    Open example
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>
    </>
  );
}
