import ThemeProvider from "react-bootstrap/ThemeProvider";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MainContentComponent from "./main-content-component";

export default function MainPage() {
  return (
    <div>
      <Navbar bg="light">
        <Container>
          <Navbar.Brand href=" https://us.tobiidynavox.com/pages/td-browse-request-form">
            <div>
              <img
                src="TD-Browse-Icon-RGB.svg"
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt="TD Browse logo"
              />
              <span className="mx-2">TD Browse Examples</span>
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container className="pt-2 pb-2 text-center container">
        <Row>
          <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <p className="lead">
              This is a test page built to demonstrate how TD Browse can provide
              embedded eye tracking within web pages. Open one of the examples below to see
              different scenarios in how it can be used.
            </p>
          </Col>
        </Row>
      </Container>
      <ThemeProvider>
        <Container>
          <MainContentComponent />
        </Container>
      </ThemeProvider>
    </div>
  );
}
